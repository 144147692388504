import type { Currency } from "~/types/currencies";
import { Api } from "~/services/swagger/Api";

const apiClient = new Api();

export const CURRENCIES: { [key: string]: Currency } = {
  USD: { code: "USD", symbol: "$", rate: 1, usdRate: 1 } as Currency,
} as const;

const currencySymbols: { [key: string]: string } = {
  USD:'$',
  SGD: "S$",
  XAF: "FCFA",
  XAG: "Ag",
  ALL: "L",
  ANG: "ƒ",
  BSD: "$",
  SLE: "Le",
  LTL: "Lt",
  SHP: "£",
  SLL: "Le",
  SOS: "Sh",
  STD: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "L",
  THB: "฿",
  AOA: "Kz",
  TJS: "SM",
  MZN: "MT",
  BTC: "₿",
  NAD: "$",
  BTN: "Nu.",
  NGN: "₦",
  BWP: "P",
  NOK: "kr",
  BYR: "Br",
  ARS: "$",
  LYD: "LD",
  AMD: "֏",
  LSL: "L",
  AWG: "ƒ",
  MDL: "L",
  AZN: "₼",
  BAM: "KM",
  MNT: "₮",
  BGN: "лв",
  MOP: "MOP$",
  BHD: ".د.ب",
  NPR: "₨",
  TMT: "m",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "$",
  TWD: "NT$",
  TZS: "Sh",
  MRU: "UM",
  UGX: "Sh",
  UAH: "₴",
  UYU: "$",
  VES: "Bs.",
  BIF: "FBu",
  VND: "₫",
  MVR: "Rf",
  MUR: "₨",
  BMD: "$",
  BND: "B$",
  MWK: "MK",
  BOB: "Bs.",
  SCR: "₨",
  VUV: "VT",
  SDG: "£",
  WST: "WS$",
  SEK: "kr",
  MXN: "$",
  MYR: "RM",
  BRL: "R$",
  MAD: "د.م.",
  BYN: "Br",
  NZD: "$",
  CAD: "$",
  PAB: "B/.",
  PGK: "K",
  PKR: "₨",
  SBD: "$",
  XAU: "Au",
  ZAR: "R",
  ZMW: "ZK",
  ZWL: "$",
  CLF: "UF",
  COP: "$",
  CUP: "$MN",
  DJF: "Fdj",
  DZD: "دج",
  ETB: "Br",
  FKP: "£",
  GGP: "£",
  GNF: "FG",
  HKD: "HK$",
  HTG: "G",
  IDR: "Rp",
  INR: "₹",
  IQD: "ع.د",
  JEP: "£",
  JPY: "¥",
  KZT: "₸",
  KWD: "د.ك",
  LAK: "₭",
  LRD: "$",
  BZD: "$",
  OMR: "ر.ع.",
  PEN: "S/",
  PYG: "₲",
  QAR: "ر.ق",
  RUB: "₽",
  UZS: "сўм",
  RWF: "FRw",
  XCD: "$",
  XOF: "CFA",
  SRD: "$",
  YER: "﷼",
  CHF: "CHF",
  CNY: "¥",
  CRC: "₡",
  CZK: "Kč",
  DKK: "kr",
  EGP: "£",
  EUR: "€",
  GBP: "£",
  GHS: "₵",
  GMD: "D",
  GYD: "$",
  HRK: "kn",
  IMP: "£",
  ISK: "kr",
  KPW: "₩",
  JOD: "د.ا",
  KGS: "с",
  KMF: "CF",
  KYD: "$",
  AED: "د.إ",
  AFN: "؋",
  LVL: "Ls",
  AUD: "$",
  MGA: "Ar",
  BBD: "$",
  MKD: "ден",
  MMK: "K",
  BDT: "৳",
  NIO: "C$",
  PHP: "₱",
  PLN: "zł",
  RON: "lei",
  RSD: "дин.",
  VEF: "Bs.",
  SAR: "﷼",
  XDR: "SDR",
  XPF: "₣",
  ZMK: "ZK",
  GTQ: "Q",
  CLP: "$",
  CNH: "¥",
  CUC: "$",
  CVE: "$",
  DOP: "RD$",
  ERN: "Nfk",
  FJD: "$",
  GEL: "₾",
  GIP: "£",
  JMD: "$",
  HNL: "L",
  HUF: "Ft",
  ILS: "₪",
  IRR: "﷼",
  KES: "Sh",
  KHR: "៛",
  KRW: "₩",
  LBP: "ل.ل",
  CDF: "FC",
  LKR: "₨",
};

export const getCurrentCurrency = (key: string | null | undefined) => {
  if (!key) return CURRENCIES[BASE_CURRENCY.code];
  return CURRENCIES[key] || CURRENCIES[BASE_CURRENCY.code];
};

export const BASE_CURRENCY = CURRENCIES.USD;

export const USD_CURRENCY = CURRENCIES["USD"];

export const getAllCurrency = async () => {
  try {
    const response = await apiClient.api.currencyRatesControllerList();
    if (!response.ok) return;

    const jsonData = await response.json();
    Object.keys(jsonData).forEach((key) => {
      const rate = jsonData[key];
      CURRENCIES[key] = {
        code: key,
        symbol: currencySymbols[key],
        rate: rate,
        usdRate: parseFloat((1 / rate).toFixed(6)),
      };
    });
  } catch (error) {
    console.log("error: ", error);
  }
};

getAllCurrency();
